"use strict";

var Sneakpeek = require('sneakpeek');
var Picturefill = require('picturefill');
var SmoothScroll = require('smooth-scroll');
var $ = require('jquery');
window.jQuery = $;

(function() {
  var $form = $('.sample-form');
  var $formMessage = $('#sample-form-message');

  $form.submit(function() {

    $.getJSON($form.attr('action') + '&c=?', $form.serialize(), function(response, responseStatus) {
      // response message to display to the user.
      $formMessage.text(response.msg.replace('0 - ', ''));
      // error/success class to be added depending on subscribe success
      $formMessage.addClass(response.result);
    });
    return false;
  });
})();

(function() {

  var browserwidth = $(window).width();
  var browserheight = $(window).height();
  var browserXCenter = browserheight / 2;
  var browserYCenter = browserwidth / 2;
  var ratio = 1.3;
  var heroVideo = $("#hero-video");
  var slideimages = $('.scale-me');

  $(heroVideo).on("loadeddata", function() {
    $(heroVideo).css("opacity", 1);
  });

  function fillemup(){

    slideimages.each(function (index, bgImg) {

      var ratio = parseFloat($(bgImg).attr('data-ratio').replace(",", "."));

      if ((browserwidth/browserheight) > ratio) {
        $(bgImg).width(browserwidth );
        $(bgImg).height(browserwidth / ratio);
      } else {
        $(bgImg).width( browserheight  * ratio);
        $(bgImg).height( browserheight);
      }

      // CENTER IMGS VERTICALLY + HORIZONTALLY
      var bgImgWidth = $(bgImg).width();
      var bgImgHeight = $(bgImg).height();
      var bgImgLeft = ( browserwidth - bgImgWidth) /2;
      var bgImgTop = ( browserheight - bgImgHeight) /2;
      $(bgImg).css('left', bgImgLeft);
      $(bgImg).css('top', bgImgTop);

    });
  }

  $(window).resize(function () {
    browserwidth = $(window).width();
    browserheight = $(window).height();
    fillemup();
  });

  fillemup();


  var navigation = document.querySelector('nav');
  Sneakpeek(document.getElementById('navigation'), {'hiddenClass':'hidden'});


  SmoothScroll.init({
    speed: 500, // Integer. How fast to complete the scroll in milliseconds
    easing: 'easeInOutCubic', // Easing pattern to use
    updateURL: true, // Boolean. Whether or not to update the URL with the anchor hash on scroll
    offset: 0, // Integer. How far to offset the scrolling anchor location in pixels
    callbackBefore: function ( toggle, anchor ) {}, // Function to run before scrolling
    callbackAfter: function ( toggle, anchor ) {} // Function to run after scrolling
  });

  $(window).scroll(function() {
    var scrolledDist = $(window).scrollTop();
    var docHeight = $(document).height();
    var winHeight = $(window).height();
    if ( scrolledDist >= 0 ) {
      $('.status-percent').css('width', 100* scrolledDist / ( docHeight - winHeight ) + '%');
    }

    if ( $(this).scrollTop() <= winHeight -40) {
      var blurVar = ( 10* $(window).scrollTop() / winHeight );
      // var blurVar = 1 - Math.round( ( $(window).scrollTop() / $(window).height() ) * 100 ) / 100;
      $('.engines').css({
         'filter'         : 'blur('+blurVar+'px)',
         '-webkit-filter' : 'blur('+blurVar+'px)',
         '-moz-filter'    : 'blur('+blurVar+'px)',
         '-o-filter'      : 'blur('+blurVar+'px)',
         '-ms-filter'     : 'blur('+blurVar+'px)'
        });

      // $('.intro-section').css('opacity',1 - Math.round( ( $(window).scrollTop() / $(window).height() ) * 100 ) / 100);
      $('nav').removeClass('compressed-nav');
    } else {
      $('nav').addClass('compressed-nav');
    }
  });

  var $name = $('#id_NAME');
  $name.on('change', function () {
    var nameSplit = $name.val().split(' ');
    $('input[name="FNAME"]').val(nameSplit[0]);
    $('input[name="LNAME"]').val(nameSplit.splice(1).join(' '));
  });

  var $allVideos = $("iframe[src^='//www.youtube.com']"),
      $fluidEl = $("body");

  $allVideos.each(function() {

    $(this)
      .data('aspectRatio', this.height / this.width)
      .removeAttr('height')
      .removeAttr('width');

  });

  $(window).resize(function() {

    var newWidth = $fluidEl.width();
    $allVideos.each(function() {
      var $el = $(this);
      $el
        .width(newWidth)
        .height(newWidth * $el.data('aspectRatio'));
    });

  }).resize();

})();
